<div *ngIf="azLoading" class="az-loading-base">
  <svg class="loader" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 300"
    style="enable-background:new 0 0 300 300;" xml:space="preserve">
    <defs>
      <style type="text/css">
        .aklsdkajsdhas {
          fill: #EFEEEE;
        }

        .ajshdjklahskjdas {
          fill: url(#SVGID_1_);
        }

        .zxcoikjhoia {
          fill: url(#SVGID_2_);
        }

        .zxmnqihwyuiha {
          fill: url(#SVGID_3_);
        }

        .jkxciuqgiugas {
          fill: #4D9834;
        }

        .ajsihgdouqiiuai {
          fill: #73B730;
        }

        .xncjiohqouihs {
          fill: url(#SVGID_4_);
        }

        .ahjsdikhasdih {
          fill: url(#SVGID_5_);
        }

        .qiuyioukxmjbik {
          fill: #FFFFFF;
        }

        [data-name*="animated-svg"] {
          stroke: red;
          stroke-dasharray: 150;
          stroke-dashoffset: 0;
          -webkit-transition: all .5s;
          -moz-transition: all .5s;
          -o-transition: all .5s;
          transition: all .5s;
          -webkit-transform-origin: 50% 50%;
          -moz-transform-origin: 50% 50%;
          -o-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-animation: stroke-logo-1 5s infinite alternate;
          -moz-animation: stroke-logo-1 5s infinite alternate;
          animation: stroke-logo-1 5s infinite alternate;
        }

        [data-name*="animated-svg--flotar"] {
          stroke: none;
          transform: translate3d(0px, 10px, 0px);
          animation: flotar 1.5s infinite alternate;
        }

        [data-name*="animated-svg--flotar--35"] {
          animation: flotar 1.5s .25s infinite alternate;
        }

        @keyframes flotar {
          from {
            transform: translate3d(0px, 0px, 0px);
          }

          to {
            transform: translate3d(0px, 10px, 0px);
          }
        }
      </style>
    </defs>
    <path class="aklsdkajsdhas"
      d="M146,283.6L24.6,213.5c-2.1-1.2-1.8-3.3,0.6-4.7l120.3-69.4c2.4-1.4,6-1.5,8.1-0.3L275,209.1 c2.1,1.2,1.8,3.3-0.6,4.7l-120.3,69.4C151.7,284.6,148.1,284.8,146,283.6z" />
    <g data-name="animated-svg animated-svg--flotar">
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="222.284" y1="95.5592" x2="25.0163" y2="253.7458">
        <stop offset="0" style="stop-color:#EFEEEE" />
        <stop offset="0.3316" style="stop-color:#DDDDDC" />
        <stop offset="0.6929" style="stop-color:#D1D0D0" />
        <stop offset="1" style="stop-color:#CDCCCC" />
      </linearGradient>
      <path class="ajshdjklahskjdas"
        d="M276.4,164.8v-23.4l-38.2,0l-84.7-48.9c-2.1-1.2-5.7-1-8.1,0.3l-84.3,48.7l-38,0l0,23.5h0 c0,0.7,0.5,1.4,1.4,1.9L146,237.1c2.1,1.2,5.7,1,8.1-0.3l120.3-69.4C275.6,166.6,276.3,165.7,276.4,164.8z" />
      <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="4.6466" y1="486.3362" x2="-174.6744"
        y2="630.1316" gradientTransform="matrix(0.866 0.5 -1 0.5774 741.8784 -139.317)">
        <stop offset="0" style="stop-color:#C3D5EF" />
        <stop offset="0.283" style="stop-color:#C7D8F0" />
        <stop offset="0.6166" style="stop-color:#D4E3F4" />
        <stop offset="0.9744" style="stop-color:#E9F5F9" />
        <stop offset="1" style="stop-color:#EBF6FA" />
      </linearGradient>
      <path class="zxcoikjhoia"
        d="M146,213.7L24.6,143.6c-2.1-1.2-1.8-3.3,0.6-4.7l120.3-69.4c2.4-1.4,6-1.5,8.1-0.3L275,139.2 c2.1,1.2,1.8,3.3-0.6,4.7l-120.3,69.4C151.7,214.7,148.1,214.9,146,213.7z" />
      <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="4.6466" y1="486.3362" x2="-174.6744"
        y2="630.1316" gradientTransform="matrix(0.866 0.5 -1 0.5774 741.8784 -139.317)">
        <stop offset="0" style="stop-color:#CDCCCC" />
        <stop offset="0.2867" style="stop-color:#D0D0D1" />
        <stop offset="0.6245" style="stop-color:#DADEDF" />
        <stop offset="0.987" style="stop-color:#EAF5F9" />
        <stop offset="1" style="stop-color:#EBF6FA" />
      </linearGradient>
      <path class="zxmnqihwyuiha"
        d="M146,213.7L24.6,143.6c-2.1-1.2-1.8-3.3,0.6-4.7l120.3-69.4c2.4-1.4,6-1.5,8.1-0.3L275,139.2 c2.1,1.2,1.8,3.3-0.6,4.7l-120.3,69.4C151.7,214.7,148.1,214.9,146,213.7z" />
      <path class="jkxciuqgiugas"
        d="M146.5,205.4L38.8,143.3c-1.8-1.1-1.6-2.9,0.5-4.1l106.6-61.5c2.1-1.2,5.3-1.4,7.2-0.3l107.6,62.1 c1.8,1.1,1.6,2.9-0.5,4.1l-106.6,61.5C151.5,206.4,148.3,206.5,146.5,205.4z" />
      <path class="ajsihgdouqiiuai"
        d="M146.7,200.2l-98.9-57.1c-1.7-1-1.5-2.7,0.5-3.8l97.9-56.5c2-1.1,4.9-1.3,6.6-0.3l98.9,57.1 c1.7,1,1.5,2.7-0.5,3.8L153.3,200C151.4,201.1,148.4,201.2,146.7,200.2z" />
    </g>
    <g data-name="animated-svg animated-svg--flotar animated-svg--flotar--35">
      <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="222.2841" y1="43.6592" x2="25.0163"
        y2="201.8458">
        <stop offset="0" style="stop-color:#EFEEEE" />
        <stop offset="0.3316" style="stop-color:#DDDDDC" />
        <stop offset="0.6929" style="stop-color:#D1D0D0" />
        <stop offset="1" style="stop-color:#CDCCCC" />
      </linearGradient>
      <path class="xncjiohqouihs"
        d="M276.4,112.9V89.5l-38.2,0l-84.7-48.9c-2.1-1.2-5.7-1-8.1,0.3L61.2,89.6l-38,0l0,23.5h0c0,0.7,0.5,1.4,1.4,1.9 L146,185.2c2.1,1.2,5.7,1,8.1-0.3l120.3-69.4C275.6,114.7,276.3,113.8,276.4,112.9z" />
      <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-47.2519" y1="441.3922" x2="-226.5729"
        y2="585.1875" gradientTransform="matrix(0.866 0.5 -1 0.5774 741.8784 -139.317)">
        <stop offset="0" style="stop-color:#EFEEEE" />
        <stop offset="1" style="stop-color:#EFEEEE" />
      </linearGradient>
      <path class="ahjsdikhasdih"
        d="M146,161.8L24.6,91.7c-2.1-1.2-1.8-3.3,0.6-4.7l120.3-69.4c2.4-1.4,6-1.5,8.1-0.3L275,87.3 c2.1,1.2,1.8,3.3-0.6,4.7l-120.3,69.4C151.7,162.8,148.1,163,146,161.8z" />
      <path class="jkxciuqgiugas"
        d="M146.5,153.5L38.8,91.4c-1.8-1.1-1.6-2.9,0.5-4.1l106.6-61.5c2.1-1.2,5.3-1.4,7.2-0.3l107.6,62.1 c1.8,1.1,1.6,2.9-0.5,4.1l-106.6,61.5C151.5,154.5,148.3,154.6,146.5,153.5z" />
      <path class="ajsihgdouqiiuai"
        d="M146.7,148.3L47.9,91.2c-1.7-1-1.5-2.7,0.5-3.8l97.9-56.5c2-1.1,4.9-1.3,6.6-0.3l98.9,57.1 c1.7,1,1.5,2.7-0.5,3.8l-97.9,56.5C151.4,149.2,148.4,149.3,146.7,148.3z" />
      <g>
        <path id="XMLID_256_" class="qiuyioukxmjbik"
          d="M107.2,77.9L132.9,63c1.8-1.1,4.7-1.2,6.3-0.3l52.6,27.9c4,2.1,1.8,6.2-4,7.5l-32.5,6.9 c-1.8,0.4-3.7,0.2-5-0.5l-43.5-23C105.2,80.5,105.4,79,107.2,77.9" />
      </g>
    </g>
  </svg>
  <p class="tips">{{ azLoadingText }}<span [ngClass]="loadingPoint >= 1 ? 'showPoint' : ''">.</span><span
      [ngClass]="loadingPoint >= 2 ? 'showPoint' : ''">.</span><span
      [ngClass]="loadingPoint === 3 ? 'showPoint' : ''">.</span></p>
</div>