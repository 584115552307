import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { appConfig } from 'src/app-config/app-config';
import { LayoutService } from '../services/layout.service';

import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';

@Component({
  selector: 'app-layout-main',
  templateUrl: './layout-main.component.html',
  styleUrls: ['./layout-main.component.scss']
})
export class LayoutMainComponent implements OnInit, AfterViewInit, OnDestroy {
  public initialized = false;
  public readonly appVersion: string = appConfig.APP_VERSION;

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (typeof this.authService.authenticated === 'boolean' && this.authService.authenticated === true) {
      this.router.navigateByUrl('/home');
    } else {
      this.layoutService.showNavigation$ = of(true);
      this.authService.logout();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialized = true;

      this.layoutService.init();
      this.layoutService.update();
      this.layoutService.setAutoUpdate(true);
    });
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.layoutService.destroy();
    });
  }
}
