import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlSelectFilterPipe } from './pipes/safe-html-select-filter.pipe';
import { AzSelectFilterContainer } from './container/az-select-filter.container';

@NgModule({
  declarations: [AzSelectFilterContainer, SafeHtmlSelectFilterPipe],
  imports: [
    CommonModule
  ],
  exports: [
    AzSelectFilterContainer
  ]
})
export class AzSelectFilterModule { }
