import { Component, OnInit } from '@angular/core';

import { MainLayoutService } from "src/app/layout/services/mainLayout.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { appConfig } from 'src/app-config/app-config';
import { of } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { Empresa } from 'src/app/layout/models/empresa';

import { RouteMemoryService } from 'src/app/shared/services/route-memory.service';
import { NgEventBus } from 'ng-event-bus';
import { LayoutService } from 'src/app/layout/services/layout.service';
import { UserInfoService } from 'src/app/auth/services/user-info.service';
import { JsonResponse } from '../../interfaces/json-response.interface';
import { TransactionalOperationEnum } from '../../enums/transactional_operation.enum';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [AsyncPipe]
})
export class SidebarComponent implements OnInit {
  public readonly BASE_TRANSLATE_SHARED = "shared";
  public user:any;

  companies: Empresa[] = [];
  private userKey = 'kreditos-username';
  
  constructor(
    public authService: AuthService,
    private mainLayoutService: MainLayoutService,
    public routeMemoryService: RouteMemoryService,
    private async: AsyncPipe,
    private eventBus: NgEventBus,
    private layoutService: LayoutService,
    private userInfoService: UserInfoService
  ) {
    this.user = localStorage.getItem(this.userKey);
  }

  ngOnInit() {

    this.getEmpresas();
  }

  getEmpresas() {
    this.mainLayoutService.getEmpresas().subscribe((trae: any) => {
      let { listCompanyUser } = trae;
			
			this.companies = listCompanyUser;
      this.authService.ALL_COMPANY = of(this.companies);
      
			if (this.companies.length) {
				this.authService.COMPANY = of(this.companies[0]);
        this.getUserInfo();
				this.authService.SELECT_COMPANY = of(true);
        localStorage.setItem(`${appConfig.PORTAL}_company`, JSON.stringify(this.companies[0]));

        this.eventBus.cast('change:empresa', { idEmpresa: this.companies[0].idEmpresa});
			}
    }, (err) => {
      console.log(err);
    });
  }

  changeCompany(event: any) {
    if (this.companies.length) {
      for (let c of this.companies) {
        if (c.idEmpresa === Number(event.target.value)) {
          this.authService.COMPANY = of(c);
          this.getUserInfo();
          localStorage.setItem(`${appConfig.PORTAL}_company`, JSON.stringify(c));
          
          if (this.async.transform(this.authService.COMPANY) != null) {
            this.authService.SELECT_COMPANY = of(true);
          }
          
          this.eventBus.cast('change:empresa', { idEmpresa: c.idEmpresa});
          this.routeMemoryService.onNav('/home');

          break;
        }
      }
    }
  }

  getUserInfo() {
    this.userInfoService.getUserProfile().subscribe((trae: JsonResponse) => {
      let { profile, transactionalOperation } = trae.response;
      let _transactionalOpertaion: TransactionalOperationEnum = transactionalOperation;
      this.authService.USERINFO = of(profile);
      this.authService.isAdmin = of(profile.systemAdministrator);

      this.authService.disabledBioID = of(_transactionalOpertaion === TransactionalOperationEnum.enabled);

      this.eventBus.cast('load:permissions', { permissions: profile.permissions });
    }, (err) => {
      console.log(err);
    });
  }
  
  toggleNavigation(close: boolean = false) {
    if (!close) {
      if (this.async.transform(this.layoutService.showNavigation$) === true) {
        this.layoutService.showNavigation$ = of(false);
      } else {
        this.layoutService.showNavigation$ = of(true);
      }
    } else {
      this.layoutService.showNavigation$ = of(true);
    }
  }

  logout() {
    this.toggleNavigation(true);
    this.authService.logout();
  }
}
