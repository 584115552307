import { Component, Input } from '@angular/core';

@Component({
	selector: 'az-not-register',
	templateUrl: './az-not-register.component.html',
	styleUrls: ['./az-not-register.component.scss']
})
export class AzNotRegisterComponent {
	public readonly BASE_TRANSLATE_SHARED = "shared.table";

	@Input() isTable: boolean = true;
	@Input() text: string = "";
}