import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import * as _ from 'lodash';

import { appConfig } from 'src/app-config/app-config';
import { AuthService } from '../services/auth.service';
import { UserInfoService } from '../services/user-info.service';
import { StatusCodeResponse } from '../../shared/models/status-code-response.enum';

import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;

  constructor(
    private authService: AuthService,
    private userInfoService: UserInfoService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authService.token;
    const username = this.userInfoService.userName;

    let contentType = 'application/json';
    if (req.headers.has('Content-Type')) {
      contentType = req.headers.get('Content-Type')!;
    }

    if (!_.isUndefined(accessToken) && !_.isEmpty(accessToken)) {
      req = req.clone({
        setHeaders: {
          'Content-Type': contentType,
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!this.refreshTokenInProgress && !_.isUndefined(username) && !_.isEmpty(username)) {
        this.refreshTokenInProgress = true;
        this.handleRefreshToken(accessToken, username, () => {
          this.refreshTokenInProgress = false;
        });
      }
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => this.handleAuthUnauthorized(event)),
      catchError((error: HttpErrorResponse) => this.handleAuthError(error))
    );
  }

  handleRefreshToken(token: string, username: string, callback: any) {
    const expires_at = this.authService.getExpiresAt;

    if (!_.isEmpty(expires_at)) {
      const today = dayjs();
      const expire = dayjs(+expires_at!);
      const diff = expire.diff(today, 'minutes');

      if (diff > 0 && diff < appConfig.REFRESH_TOKEN_MINUTES) {
        this.authService
          .refreshToken(token, username)
          .pipe(
            catchError((err) => {
              console.log(err);
              return of(err);
            })
          )
          .subscribe(() => {
            callback();
          });
      } else {
        callback();
      }
    }
  }

  private handleAuthUnauthorized(event: HttpEvent<any>): any {
    if (event instanceof HttpResponse) {
      let body: any;
      if (_.isArray(event.body)) {
        body = _.head(event.body);
      } else {
        body = event.body;
      }

      const code = _.get(body, 'code', StatusCodeResponse.OK);
      

      if (_.eq(code, StatusCodeResponse.Unauthorized)) {
        this.authService.logout();
      }
    }
    return event;
  }

  private handleAuthError(errorResponse: HttpErrorResponse): Observable<any> {
    const code = _.get(errorResponse.error, 'code', StatusCodeResponse.OK);
    if (_.eq(code, StatusCodeResponse.Unauthorized)) {
      this.authService.logout();
    }
    return throwError(errorResponse);
  }
}
