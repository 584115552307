import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import * as moment from 'moment';

import { appConfig } from 'src/app-config/app-config';
import { AuthResult } from '../models/auth-result';
import { AuthCredential } from '../models/auth-credential';
import { RefreshTokenBody } from '../models/refresh-token-body';

import { Empresa } from 'src/app/layout/models/empresa';
import { UserProfile } from '../models/user-profile';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly baseTokenServiceUrl = "https://apicreditapp.keotecnologia.com";
  private readonly baseRecepApiServiceUrl = appConfig.AUDIT_API_URL + '/' + appConfig.RECEPTION_API_WAR_NAME;

  private authUrl = this.baseTokenServiceUrl + '/api/auth/local';
  private refreshTokenUrl = this.baseTokenServiceUrl + '/services/sso/refresh';

  private logoutAuditUrl = this.baseRecepApiServiceUrl + '/v1/common/logout';

  private authFlag = appConfig.PORTAL + '-is_logged_in';
  private authTokenKey = appConfig.PORTAL + '-access_token';
  private estadoClave = appConfig.PORTAL + '-estado_clave';
  private expiresAt = appConfig.PORTAL + '-expires_at';
  private portal = appConfig.PORTAL;
  private timeoutId = appConfig.PORTAL + '-timeout_id';
  private user = 'kreditos-username';

  public authSuccessUrl = '/home';

  public isAdmin: Observable<boolean> = of(false);
  public disabledBioID: Observable<boolean> = of(false);

  message_srtl: any;
  time_srtl: any;

  public SELECT_COMPANY: Observable<boolean> = of(false);
  public COMPANY: Observable<Empresa | null> = of(null);
  public USERINFO: Observable<UserProfile | null> = of(null);
  public ALL_COMPANY: Observable<Empresa[]> = of([]);

  constructor(
    private http: HttpClient,
    private rolesService: NgxRolesService,
    private permissionsService: NgxPermissionsService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  public get authenticated(): boolean {
    return JSON.parse(localStorage.getItem(this.authFlag)!);
  }

  public get token(): string {
    return JSON.parse(localStorage.getItem(this.authTokenKey)!);
  }

  public get getEstadoClave() {
    return localStorage.getItem(this.estadoClave);
  }

  public get getExpiresAt() {
    return localStorage.getItem(this.expiresAt);
  }

  public get _getRouterMemory() {
    let t: any = localStorage.getItem(`${appConfig.PORTAL}-router-memory`);
    return t;
  }

  public login(authCredential: AuthCredential): Observable<any> {
    // return of(data);
    return this.http.post<any>(this.authUrl, authCredential);
  }

  public setAuth(authResult: AuthResult): void {
    clearTimeout(this.message_srtl);
    clearTimeout(this.time_srtl);

    const expiracion = 60;
    const expirationDate = moment()
      .add(+expiracion, 'minutes')
      .subtract(1000, 'milliseconds');
    console.log("lo que se va a guardar ", authResult)
    localStorage.setItem(this.authFlag, JSON.stringify(true));
    localStorage.setItem(this.authTokenKey, JSON.stringify(authResult.jwt));
    localStorage.setItem(this.estadoClave, authResult.estado);
    localStorage.setItem(this.expiresAt, expirationDate.valueOf().toString());
    localStorage.setItem(this.timeoutId, expiracion.toString());
    localStorage.setItem(this.user, authResult.user.username.toString());


    this.setRedirectToLogin();
  }

  public setAuthFromStorage() {
    const expirationDate: any = localStorage.getItem(this.expiresAt);
    let tokenTimeLeft: any = moment(+expirationDate).valueOf() - moment().local().valueOf();
    localStorage.setItem(this.expiresAt, tokenTimeLeft.valueOf().toString());
    this.setRedirectToLogin();
  }

  public logout(redirect: boolean = true) {
    localStorage.clear();

    this.SELECT_COMPANY = of(false);
    this.COMPANY = of(null);
    this.ALL_COMPANY = of([]);
    this.USERINFO = of(null);
    this.isAdmin = of(false);
    this.disabledBioID = of(false);

    clearTimeout(this.message_srtl);
    clearTimeout(this.time_srtl);

    this.rolesService.flushRoles();
    this.permissionsService.flushPermissions();
    this.modalService.dismissAll();
    Swal.close();

    if (redirect) {
      this.router.navigate(['/login']);
    }
  }

  public logoutAudit() {
    return this.http.post<any>(this.logoutAuditUrl, null).pipe(catchError(this.handleError));
  }

  public refreshToken(currToken: string, username: string) {
    const body: RefreshTokenBody = {
      authorization: currToken,
      portal: this.portal,
      login: username
    };

    return this.http.post<AuthResult>(this.refreshTokenUrl, body).pipe(
      tap(() => console.log('---> token refreshed')),
      tap((data) => this.setAuth(data)),
      catchError(this.handleError)
    );
  }

  private handleError(err: any) {
    return throwError(err);
  }

  private setRedirectToLogin(): any {
    let x: any = localStorage.getItem(this.timeoutId);
    x = (parseInt(x) * 60) * 1000;

    this.message_srtl = setTimeout(() => {
      this.snackBar.open("La sesión va a expirar en 10 minutos.", '', { panelClass: 'warn', duration: 10000 });
    }, x - 600000);

    this.time_srtl = setTimeout(() => {
      this.logout();
    }, x);
  }
}
