<ng-container *ngIf="getItemAction(); else noActions">
  <ng-container *ngFor="let action of actions">
    <span
      *ngIf="getActionActive(action) === true"
      class="tools__item" az-tooltip
      [class]="getActionBadge(action) && getValueBadge() != '0' ? 'badge' : ''"
      [ngClass]="getClassesFromAction(action)"
      [attr.data-badge]="getValueBadge()"
      (click)="actionClicked($event, action)"
    >
      <az-tooltip [azTooltipText]="getTooltipFromAction(action) | translate" azTooltipPosition="left"></az-tooltip>
    </span>
  </ng-container>
</ng-container>
<ng-template #noActions>
  <ng-container *ngFor="let action of actions">
    <span
      *ngIf="getActionBadge(action)"
      class="tools__item" az-tooltip
      [class]="getActionBadge(action) && getValueBadge() != '0' ? 'badge' : ''"
      [ngClass]="getClassesFromAction(action)"
      placement="bottom"
      [attr.data-badge]="getValueBadge()"
      (click)="actionClicked($event, action)"
    >
      <az-tooltip [azTooltipText]="getTooltipFromAction(action) | translate" azTooltipPosition="left"></az-tooltip>
    </span>
    <span
      *ngIf="!getActionBadge(action)"
      class="tools__item" az-tooltip
      [ngClass]="getClassesFromAction(action)"
      (click)="actionClicked($event, action)"
    >
      <az-tooltip [azTooltipText]="getTooltipFromAction(action) | translate" azTooltipPosition="left"></az-tooltip>
    </span>
  </ng-container>
</ng-template>