import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'az-loading-base',
  templateUrl: './az-loading-base.component.html',
  styleUrls: ['./az-loading-base.component.scss']
})
export class AzLoadingBaseComponent implements OnInit {
  componentActive = true;
  loadingPoint: number = 0;
  setIntervalLoading: any;

  @Input() azLoading: boolean = false;
  @Input() azLoadingText: string = "";

  constructor() {}

  ngOnInit(): void {
    this.setLoadingPoint();
  }

  setLoadingPoint() {
    let i = 0;
    this.setIntervalLoading = setInterval(() => {
      i++;
      if (i === 1) {
        this.loadingPoint = 1;
      }

      if (i === 2) {
        this.loadingPoint = 2;
      }

      if (i === 3) {
        this.loadingPoint = 3;
      }

      if (i === 4) {
        this.loadingPoint = 0;
        i = 0;
      }
    }, 800);
  }
}
