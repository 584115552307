import { Component } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { ResumenClienteService } from './resumen-cliente.service';


@Component({
  selector: 'app-resumen-cliente',
  templateUrl: './resumen-cliente.component.html',
  styleUrls: ['./resumen-cliente.component.scss']
})
export class ResumenClienteComponent {
  public creditosVigentes: any[] = [];
  public creditosPagados: any[] = [];
  public creditosCancelados: any[] = [];
  public numCreditosPestana: number = 0;
  public nombreCliente: string;
  public correoCliente: string;
  public numTelfCliente: string;
  public interesTotalCliente: number;
  public interesesAcumuladosCliente: number;
  public capitalAdeudadoCliente: number;

  cambioPestana(event){
    if(event.index == 0){
      this.numCreditosPestana = this.creditosVigentes.length;
    }
    if (event.index == 1){
      this.numCreditosPestana = this.creditosPagados.length;
    }
    if (event.index == 2){
      this.numCreditosPestana = this.creditosCancelados.length;
    }
  }

  constructor(
    private datoURL: ActivatedRoute,
    private resumenClienteService: ResumenClienteService
  ){
    this.datoURL.params.subscribe(paramURL => {
      console.log(paramURL.id);
      this.resumenClienteService.obtenerCreditosCliente(paramURL.id).subscribe(datos => {
        console.log(datos);
        this.correoCliente = datos.data[0].cliente.correo;
        this.numTelfCliente = datos.data[0].cliente.telefono;
        this.interesTotalCliente = datos.datosGenerales.totalInteresesActuales;
        this.capitalAdeudadoCliente = datos.datosGenerales.totalSaldosPendientesCreditos;
        this.interesesAcumuladosCliente = datos.datosGenerales.totalInteresesAcumulados;
        datos.data.forEach(credito => {
          if (/*credito.estado_credito == "Vigente"*/ credito.monto_credito > 5000){
            this.creditosVigentes.push(credito);
          }
          if(/*credito.estado_credito == "Pagado"*/ credito.monto_credito < 5000){
            // this.totalCreditosPagados++;
            this.creditosPagados.push(credito)
          }
          if (credito.estado_credito == "Cancelado"){
            // this.totalCreditosCancelados++;
          }
        });
        this.numCreditosPestana = this.creditosVigentes.length;
        this.nombreCliente = `${datos.data[0].cliente.nombre} ${datos.data[0].cliente.apellido}`
        // this.datosCreditos = datos.data;
      })
    })
  }
}
