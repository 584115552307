<div class="wrapper card-group" dragula="COLUMNS" [(dragulaModel)]="groups">
  <div class="card shadow-none bg-transparent border-primary border-0" *ngFor="let group of groups">
    <h6 class="card-header with-elements text-primary border-bottom-primary">
      <div class="card-header-title font-weight-bold">
        {{ group.translationKey ? (group.translationKey | translate) : group.name }}
      </div>
    </h6>
    <ul class="list-group list-group-flush h-100" dragula="ITEMS" [(dragulaModel)]="group.items">
      <li *ngFor="let item of group.items" class="list-group-item list-group-item-hover">
        <ng-container
          *ngTemplateOutlet="itemOutlet ? itemOutlet : defaultItemOutlet; context: { $implicit: item }"
        ></ng-container>
        <ng-template #defaultItemOutlet>
          {{ item }}
        </ng-template>
      </li>
    </ul>
  </div>
</div>
