<div class="wrapper-nav">
    <div class="brand">
        <img class="logo" src="assets/img/logo/logo.png" alt="Azurian Bio ID">
    </div>

    <div class="nav-app">
        <div class="item op" routerLink="/home" routerLinkActive="active">
            <span class="icon fas fa-home"></span>
            {{ NAVIGATION_TRANSLATE + '.start' | translate }}
        </div>
        <ng-container *ngIf="(authService.COMPANY | async) != null">
            <div (click)="showOption($event, 'administracion')" class="item opacity-1" routerLinkActive="active">
                <span class="icon fas fa-cog"></span>
                {{ NAVIGATION_TRANSLATE + '.administration' | translate }}
                <span class="arrow-icon fas fa-angle-left"></span>
                <div *ngIf="showOptionNav === 'administracion'" class="subnav">
                    <div *ngIf="validatorsUtils.validPermissions((authService.USERINFO | async)?.permissions, actionTypePermission.READ_TRANSACTIONS)" (click)="showOption($event, 'administracion', true)" class="item" routerLink="/administracion/clientes" routerLinkActive="active">
                        Clientes
                    </div>
                    <div *ngIf="validatorsUtils.validPermissions((authService.USERINFO | async)?.permissions, actionTypePermission.READ_TRANSACTIONS)" (click)="showOption($event, 'administracion', true)" class="item" routerLink="/administracion/creditos" routerLinkActive="active">
                        Credito
                    </div>
                    <div *ngIf="validatorsUtils.validPermissions((authService.USERINFO | async)?.permissions, actionTypePermission.READ_TRANSACTIONS)" (click)="showOption($event, 'administracion', true)" class="item" routerLink="/administracion/pagos" routerLinkActive="active">
                       Pagos
                    </div>
                </div>
            </div>

            <div (click)="showOption($event, 'billing')" class="item opacity-1" routerLinkActive="active">
                <span class="icon fi fi-sr-chart-line-up"></span>
                Reportes
                <span class="arrow-icon fas fa-angle-left"></span>
                <div *ngIf="showOptionNav === 'billing'" class="subnav">
                    <div *ngIf="validatorsUtils.validPermissions((authService.USERINFO | async)?.permissions, actionTypePermission.READ_TRANSACTIONS)" (click)="showOption($event, 'billing', true)" class="item" routerLink="/integraciones/zorro" routerLinkActive="active">
                       Tabla Ng Zorro
                    </div>
                    <div *ngIf="validatorsUtils.validPermissions((authService.USERINFO | async)?.permissions, actionTypePermission.READ_TRANSACTIONS)" (click)="showOption($event, 'billing', true)" class="item" routerLink="/integraciones/prime" routerLinkActive="active">
                        Tabla PrimeNG
                    </div>
                    <div *ngIf="validatorsUtils.validPermissions((authService.USERINFO | async)?.permissions, actionTypePermission.READ_TRANSACTIONS)" (click)="showOption($event, 'billing', true)" class="item" routerLink="/billing" routerLinkActive="active">
                        Otros
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>