import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { appConfig } from 'src/app-config/app-config';

@Injectable({
    providedIn: "root"
})
export class ValidatorsUtils {
    public EX_EMAIL: RegExp = /^[a-zA-Z0-9.!#$'*+/=?^_`{|}~-]+@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
   // public EX_DOMINIO_EMAIL: RegExp = /^@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    public EX_IP: RegExp = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    public p12_2: RegExp = /^\d{1,12}(\.\d{1,2})?$/;
    constructor(private translate: TranslateService) { }

    public onlyTextEmail(event: any) {
        event.target.value = event.target.value.trim();

        let regex = /^[a-zA-Z\u00C0-\u017F0123456789.!#$'*+/=?^@_`{|}~-]+$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            return true;
        } else {
            return false;
        }
    }

    public onlyTextTypeUsername(event: any) {
        event.target.value = event.target.value.trim();

        let regex = /^[a-zA-Z\u00C0-\u017F0123456789]+$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            return true;
        } else {
            return false;
        }
    }

    public onlyTextAndSpaces(event: any) {
        event.target.value = event.target.value.trimStart();
        event.target.value = event.target.value.replace(/[`1234567890`~!°@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        if (event.key === '-' && event.target.value.length === 1) {
            event.target.value = "";
        }
        let regex = /^[a-zA-Z\u00C0-\u017F -]+$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            return true;
        } else {
            return false;
        }
    }

    public onlyTextNumberAndSpaces(event: any) {
        event.target.value = event.target.value.trimStart();
        event.target.value = event.target.value.replace(/[``~!°@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        if (event.key === '-' && event.target.value.length === 1) {
            event.target.value = "";
        }
        let regex = /^[a-zA-Z\u00C0-\u017F0123456789 -]+$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            return true;
        } else {
            return false;
        }
    }

    public onlyNumberAndPoints(event: any, validpoint: boolean = false) {
        event.target.value = event.target.value.trim();
        let regex = validpoint ? /^[0-9\.]$/ : /^[0-9]$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            if (validpoint) {
                if (event.target.value.includes('.') && event.key === '.') {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    public printParameters(parametro: string, valor: string) {
        if (parametro === '') {
            return `?${valor}`;
        } else {
            return `${parametro}&${valor}`;
        }
    }

    public validPermissions(permission: string[] | undefined, validPermissions: string): boolean {
        let _valid = false;

        if (permission != undefined) {
            if (permission.length) {
                for (let p of permission) {
                    if (p.toLowerCase() === validPermissions.toLowerCase()) {
                        _valid = true;
                        break;
                    }
                }
            }
        }

        return _valid;
    }

    downLoadFile(data: string, type: string, name: string) {
        let binary = atob(data);

        let array = [];
        for (let j = 0; j < binary.length; j++) {
            array.push(binary.charCodeAt(j));
        }

        let blob = new Blob([new Uint8Array(array)], { type: type });
        let w: any = window.navigator;
        if (w && w.msSaveOrOpenBlob) {
            w.msSaveOrOpenBlob(blob);
            return;
        }
        let url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = name;
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );
    }

    public equals(rows1: any, rows2: any): boolean {
        return JSON.stringify(rows1) === JSON.stringify(rows2);
    }
}