import { IAppConfig } from './app-config.interface';

export const defaultAppConfig: IAppConfig = {
  "APP_VERSION": "DEV",
  "APP_URL": "http://localhost:8080",
  "AUTH_API_URL": "http://localhost:8584",
  "SCORING_API_URL": "http://localhost:8082",
  "AUDIT_API_URL": "http://localhost:8083",
  "LAYOUT_API_URL": "http://localhost:8083",
  "RECEPCION_API_URL": "http://localhost:8083",
  "MASSIVE_DOWNLOAD_API_URL": "http://localhost:8083",
  "COMPANY_API_URL": "http://localhost:8082",
  "SYSTEM_PROPERTIES_API_URL": "http://localhost:8082",
  "USER_API_URL": "http://localhost:8082",
  "NOTIFICATIONS_API_URL": "http://localhost:8082",
  "PASSWORD_POLICIES_API_URL": "http://localhost:8082",
  "ROLES_API_URL": "http://localhost:8082",
  "SYSTEM_ACCOUNTS_API_URL": "http://localhost:8082",
  "ADDRESSEE_CONFIG_API_URL": "http://localhost:8082",
  "BLACK_LIST_API_URL": "http://localhost:8082",
  "WHITE_LIST_API_URL": "http://localhost:8082",
  "DTE_XML_API_URL": "http://localhost:8082",
  "ORDER_VALIDATION_API_URL": "http://localhost:8082",
  "VALIDATION_RULES_API_URL": "http://localhost:8082",
  "DIGITAL_CERTIFICATION_API_URL": "http://localhost:8082",
  "PURCHASE_ORDERS": "http://localhost:8082",
  "DTE_CEN": "http://localhost:8083",
  "HES": "http://localhost:8082",
  "REFRESH_TOKEN_MINUTES": 5,
  "AZURE_LOGIN": false,
  "PORTAL": "bioid",
  "AMBIENTE": 0,
  "RECEPTION_ADMINISTRATION_WAR_NAME": "Adm-bioid-rest",
  "RECEPTION_API_WAR_NAME": "azurianbioid",
  "TOKEN_WAR_NAME": "token"
};
