export enum MenuTypePermission {
  ADMINISTRACION = 'ADMINISTRACION',

  ADMINISTRACION_EMPRESAS = 'ADMINISTRACION_EMPRESAS',
  ADMINISTRACION_CUENTAS_DE_SISTEMA = 'ADMINISTRACION_CUENTAS_DE_SISTEMA',
  ADMINISTRACION_POLITICAS_DE_CLAVE = 'ADMINISTRACION_POLITICAS_DE_CLAVE',
  ADMINISTRACION_AUDITORIA_DE_SISTEMA = 'ADMINISTRACION_AUDITORIA_DE_SISTEMA',
  ADMINISTRACION_ROLES = 'ADMINISTRACION_ROLES',
  ADMINISTRACION_USUARIOS = 'ADMINISTRACION_USUARIOS',
  ADMINISTRACION_PROPIEDADES_SISTEMA = 'ADMINISTRACION_PROPIEDADES_SISTEMA',
  ADMINISTRACION_ALERTAS_NOTIFICACIONES = 'ADMINISTRACION_ALERTAS_NOTIFICACIONES',
  ADMINISTRACION_NOTIFICACIONES_DE_RECHAZO = 'ADMINISTRACION_NOTIFICACIONES_DE_RECHAZO',
  ADMINISTRACION_UNIDAD_ADMINISTRATIVA = 'ADMINISTRACION_UNIDAD_ADMINISTRATIVA',
  ADMINISTRACION_SUB_UNIDAD_ADMINISTRATIVA = 'ADMINISTRACION_SUB_UNIDAD_ADMINISTRATIVA',
  ADMINISTRACION_RUT_EXCLUIDO = 'ADMINISTRACION_RUT_EXCLUIDO',
  ADMINISTRACION_CONTACTOS_PROVEEDORES = 'ADMINISTRACION_CONTACTOS_PROVEEDORES',
  ADMINISTRACION_REGLAS_VALIDACION = 'ADMINISTRACION_REGLAS_VALIDACION',
  ADMINISTRACION_CERTIFICADO_DIGITAL = 'ADMISNITRACION_CERTIFICADO_DIGITAL',

  RECEPCION_BUSCADOR_RECEPCIONADOS = 'RECEPCION_BUSCADOR_RECEPCIONADOS',

  SCORING = 'SCORING',

  DESCARGA_MASIVA = 'DESCARGA_MASIVA',

  ADDONS_CEN_BUSCADOR_DTE_CEN = 'ADDONS_CEN_BUSCADOR_DTE_CEN',
  ADDONS_CEN_GENERADOR_CSV_CEN = 'ADDONS_CEN_GENERADOR_CSV_CEN',

  ORDENES_DE_COMPRA_BUSCADOR = 'ORDENES_DE_COMPRA_BUSCADOR',

  MODULO_HES = 'MODULO_HES'
}
