import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import { UserData } from '../models/usuario';
import { appConfig } from 'src/app-config/app-config';
import { CodeResponse } from '../models/codeResponse';

@Injectable({
  providedIn: 'root'
})
export class MainLayoutService {
  private readonly baseServiceUrl = appConfig.LAYOUT_API_URL + '/' + appConfig.RECEPTION_API_WAR_NAME;

  private userUrl = this.baseServiceUrl + '/v1/user/user';

  data = {
    "nameService": "Get_Empresas",
    "message": "Accepted",
    "code": 0,
    "listCompanyUser": [
      {
        "idEmpresa": 96913280,
        "razonSocial": "Azurian Tecnologia Limitada",
        "idRepresentante": 12262091,
        "nombreRepresentante": "Jeanette Fuenzalida",
        "direccion": "Av. Los Militares 4777, Torre 1, Piso 10",
        "comuna": "Las Condes",
        "ciudad": "Santiago",
        "telefono": "+56 22 402 5400",
        "estadoContingencia": null,
        "giroEmisor": "Empresas de Servicios Integrales De Informatica",
        "rolEmpresas": [
          {
            "idRol": 8,
            "idEmpresa": 20601297729
          }
        ]
      }
    ]
  }

  constructor(private http: HttpClient) { }

  getEmpresas(): Observable<any> {
    return of(this.data);
    // return this.http.get<EmpresaData>(this.empresasUrl).pipe(
    //   map((data) => {
    //     if (data.code === CodeResponse.ACCEPTED) {
    //       return data;
    //     } else {
    //       console.error(data.message);
    //       return data;
    //     }
    //   }),
    //   catchError(this.handleError)
    // );
  }

  getUser(): Observable<UserData> {
    return this.http.get<UserData>(this.userUrl).pipe(
      map((data: any) => {
        if (data.code === CodeResponse.ACCEPTED) {
          return data;
        } else {
          console.error(data.message);
        }
      }),
      catchError(this.handleError)
    );
  }

  private handleError(err: any) {
    return throwError(err.message);
  }
}
