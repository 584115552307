<div class="wrapper-layout" [ngClass]="{'layout-loading': !initialized, 'fullscreen': !(layoutService.showNavigation$ | async)}">
  <div class="wrapper-layout--navbar" [ngClass]="{'no-nav': !(layoutService.showNavigation$ | async)}">
    <app-nav></app-nav>
  </div>
  <div class="wrapper-layout--content">
    <div class="sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="router-outlet">
      <router-outlet></router-outlet>

      <footer class="footer">
        <a class="footer__notcopy" rel="noopener noreferrer" href="https://www.Google.com" target="_blank">© CrediYeri | Todos los derechos reservados.</a>
        <p class="footer__version"> CrediYeri - 0.0.1
        </p>
      </footer>
    </div>
  </div>
</div>