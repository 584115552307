import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActionTypePermission } from 'src/app/auth/action-type-permission.enum';
import { MenuTypePermission } from 'src/app/auth/menu-type-permission.enum';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ValidatorsUtils } from 'src/app/utils/validators.util';
import { TransactionalOperationService } from '../../services/transactional_operation.service';
import { TransactionalOperationEnum } from '../../enums/transactional_operation.enum';
import { JsonResponse } from '../../interfaces/json-response.interface';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  providers: [AsyncPipe]
})
export class NavComponent implements OnInit {
  public readonly BASE_TRANSLATE_TRANSACTIONAL_OPERATION = 'transactionalOperation';
  public readonly BASE_TRANSLATE_SHARED = 'shared';
  public readonly NAVIGATION_TRANSLATE = 'generic_components.navigation';

  public readonly actionTypePermission = ActionTypePermission;
  public readonly menuPermission = MenuTypePermission;

  loadToggleTO: boolean = true;

  showOptionNav: string | null = null;
  myPermission: string[] = [];

  constructor(
    public authService: AuthService,
    public validatorsUtils: ValidatorsUtils,
    private async: AsyncPipe,
    private transactionalOperationService: TransactionalOperationService,
    private translate: TranslateService,
    private alert: MatSnackBar
  ) { }

  public ngOnInit(): void { }

  changeToggleBioId() {
    let isActive: boolean = this.async.transform(this.authService.disabledBioID)!;
    let oldState: boolean = isActive;
    let service: Observable<any> = of();

    Swal.fire({
      icon: "question",
      iconHtml: '<span class="fas fa-question"></span>',
      title: isActive ? this.translate.instant(`${this.BASE_TRANSLATE_TRANSACTIONAL_OPERATION}.message.sms_1`) : this.translate.instant(`${this.BASE_TRANSLATE_TRANSACTIONAL_OPERATION}.message.sms_2`),
      showCancelButton: false,
      showDenyButton: true,
      allowOutsideClick: false,
      denyButtonText: this.translate.instant(`${this.BASE_TRANSLATE_SHARED}.buttons.btn_cancel`),
      confirmButtonText: this.translate.instant(`${this.BASE_TRANSLATE_SHARED}.buttons.btn_accept`),
    }).then((result) => {
      if (result.isConfirmed) {
        if (isActive) {
          this.authService.disabledBioID = of(false);
          service = this.transactionalOperationService.changeTransactionalOperation(TransactionalOperationEnum.disabled);
        } else {
          this.authService.disabledBioID = of(true);
          service = this.transactionalOperationService.changeTransactionalOperation(TransactionalOperationEnum.enabled);
        }

        service.subscribe((trae: JsonResponse) => {
          console.log(trae);
          this.alert.open(this.translate.instant(`${this.BASE_TRANSLATE_TRANSACTIONAL_OPERATION}.message.sms_3`), '', { panelClass: 'success', duration: 3000 });
        }, (err: HttpErrorResponse) => {
          console.log(err);
          this.alert.open(this.translate.instant(`${this.BASE_TRANSLATE_TRANSACTIONAL_OPERATION}.message.sms_4`), '', { panelClass: 'error', duration: 3000 });
        });
      } else {
        this.loadToggleTO = false;
        this.authService.disabledBioID = of(oldState);

        setTimeout(() => this.loadToggleTO = true, 200);
      }
    })
  }

  showOption(event: any, option: string, close: boolean = false) {
    event.stopPropagation();

    if (!close) {
      if (this.showOptionNav === option && event.target.classList[1] != 'active') {
        this.showOptionNav = null;
      } else {
        this.showOptionNav = option;
      }
    } else {
      this.showOptionNav = option;
    }
  }
}
