import * as _ from 'lodash';
import { FormControl } from '@angular/forms';

export class RucHelper {
    public static clearFormat(value: string): string {
        let x = _.replace(value, /[\.\-]/g, '');
        return _.replace(x, / /g, '');
    }

    public static format(value: string, useThousandsSeparator: boolean): string {
        let cRuc = this.clearFormat(value);
        return _.replace(cRuc, /[a-zA-Z]/g, '');
    }

    public static extractBody(value: string): string {
        const temp = _.slice(value, 0, -1);
        return _.join(temp, '');
    }

    public static extractCheckDigit(value: string): string {
        const temp = _.slice(value, -1);
        return _.join(temp, '');
    }

    // FUENTE: https://es.stackoverflow.com/questions/42957/c%C3%B3mo-validar-un-ruc-de-per%C3%BA
    // ¿Cómo se valida el dígito verificador de un RUC?
    // Estudiar la imagen.
    public static calculateCheckDigit(value: string): string {
        let dv: any, suma_regla, regla;
        if (parseInt(value) % 1 === 0) {
            const first = value.substr(0, 2);

            if (first === "10" || first === "15" || first === "16" || first === "17" || first === "20") {
                let r = <any>value.split(""); // 20503644968 = transformar array

                regla = {
                    n1: r[0] * 5,
                    n2: r[1] * 4,
                    n3: r[2] * 3,
                    n4: r[3] * 2,
                    n5: r[4] * 7,
                    n6: r[5] * 6,
                    n7: r[6] * 5,
                    n8: r[7] * 4,
                    n9: r[8] * 3,
                    n10: r[9] * 2
                };

                suma_regla = regla.n1 + regla.n2 + regla.n3 + regla.n4 + regla.n5 + regla.n6 + regla.n7 + regla.n8 + regla.n9 + regla.n10; // 157
                dv = suma_regla % 11; // 157 % 11 = 3
                dv = 11 - dv; // 11 - 3 = 8
            }
        }

        if (String(value).length === 10) {
            return dv;
        } else {
            return "";
        }
    }

    public static checkRuc(value: string): boolean {
        const temp = this.clearFormat(value);
        const body = this.extractBody(temp);
        const dv = this.extractCheckDigit(temp);
        const checkDigitExpected = this.calculateCheckDigit(body);

        return _.eq(_.toUpper(dv), _.toUpper(checkDigitExpected));
    }
}
