import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { appConfig } from 'src/app-config/app-config';

@Injectable({
    providedIn: 'root'
})
export class RouteMemoryService {
    constructor(
        private router: Router
    ) {}
    
    onNav(path: string) {
        localStorage.setItem(`${appConfig.PORTAL}-router-memory`, path);
        this.router.navigateByUrl(path);
    }
}