import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {};

export const MULTI_CHECKBOX_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AzMultiCheckboxComponent),
  multi: true,
};

@Component({
  selector: 'az-multi-checkbox',
  templateUrl: './az-multi-checkbox.component.html',
  styleUrls: ['./az-multi-checkbox.component.scss'],
  providers: [MULTI_CHECKBOX_CONTROL_VALUE_ACCESSOR],
})
export class AzMultiCheckboxComponent implements ControlValueAccessor {
  // #region Properties

  private innerValue: Array<any> | null = [];
  private onChangeCallback: (_: Array<any> | null) => void = noop;
  private onTouchedCallback: () => void = noop;

  @Input() public disabled: boolean = false;
  @Input() items!: Array<any>;

  // #endregion Properties

  // #region Constructors

  constructor() {}

  // #endregion Constructors

  // #region Public Accessors

  public get value(): Array<any> | null {
    return this.innerValue;
  }

  public set value(v: Array<any> | null) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // #endregion Public Accessors

  // #region Public Methods

  public onBlur() {
    this.onTouchedCallback();
  }

  public registerOnChange(fn: (value: Array<any> | null) => any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: Array<any> | null): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // #endregion Public Methods
}
