import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { LayoutBlankComponent } from './layout-blank/layout-blank.component';
import { LayoutMainComponent } from './layout-main/layout-main.component';
import { LayoutService } from './services/layout.service';

import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    LayoutBlankComponent,
    LayoutMainComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
  ],
  providers: [LayoutService],
})
export class LayoutModule {}
