import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { loadAppConfig } from './app-config/app-config';

declare var window: any;

const base = window.document.getElementById('base');
const baseHref = base.getAttribute('href');

const isUsingNgServe = baseHref === '/';
const appConfigPath = isUsingNgServe
  ? `${baseHref}assets/app-config.json`
  : `${baseHref}config/getApplicationProperties`;

fetch(appConfigPath).then((response) => {
  console.log(`--- fetch first status | ${response.status} ---`);
  return response.json()
}).then((config) => {
  console.log(`--- fetch second config | ${config.PORTAL} ---`);
  window._azurianFrontEndConfiguration = { ...config };

  loadAppConfig(config);


  const options = {
    preserveWhitespaces: false
  };

  if (environment.production) {
    enableProdMode();
  } else {
    options.preserveWhitespaces = true;
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule, options)
    .catch((err) => console.log(err));
}).catch((error) => {
  console.error(`Failed to fetch the application configuration from the service ${appConfigPath}`);
  console.error(error);
});
