import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResumenClienteService {
  private URLDetallesCliente: string = "https://apicreditapp.keotecnologia.com/api/creditosCustomInterestByDay";
  constructor(private http: HttpClient) { }

  private headers = new HttpHeaders()
    .set('Authorization', 'Bearer 084fd5baaa7105e9a54cc56a786651238d465d6e6150eb3e5d611633957972bd1d22cc106cdfeb78f6da4d0bf1a703d1d7b1dd647a8c0dd58aaece5c0474781149750e7c042089ca90661cf390a094b8f88a377a05894806e2a767c69c2ceaa5ceac5406da6a54a104f6274b744ba28b955dc4c17b189b22c51fe02ff1db2711')

  private Headers = {
    headers: this.headers
  }

  obtenerCreditosCliente(idCliente: any): Observable<any> {
    return this.http.get(`${this.URLDetallesCliente}?id_cliente=${idCliente}`, this.Headers);
  }
}
