import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';
import { LayoutMainComponent } from './layout/layout-main/layout-main.component';

import { AuthGuard } from './auth/guards/auth.guard';
import { ResumenClienteComponent } from './resumen-cliente/resumen-cliente.component';

const routes: Routes = [
  {
    path: "resumenCliente/:id",
    component: ResumenClienteComponent
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'login',
    component: LayoutBlankComponent,
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: LayoutMainComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
      },
      {
        path: 'administracion',
        loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationModule)
      },
      {
        path: 'integraciones',
        loadChildren: () => import('./integraciones/integraciones.module').then((m) => m.IntegracionesModule)
      },
      {
        path: 'management',
        loadChildren: () => import('./management/management.module').then((m) => m.ManagementModule)
      },
      {
        path: 'billing',
        loadChildren: () => import('./billing/billing.module').then((m) => m.BillingModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule)
      }
    ],
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'inicio',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
