import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { appConfig } from 'src/app-config/app-config';
import { TransactionalOperationEnum } from '../enums/transactional_operation.enum';
import { JsonResponse } from '../interfaces/json-response.interface';

@Injectable({
    providedIn: 'root'
})
export class TransactionalOperationService {
    private headers = new HttpHeaders({ 'Accept': 'application/json' });
    private baseUrl: String = "";

    constructor(
        private http: HttpClient
    ) {
        this.baseUrl = `${appConfig.RECEPCION_API_URL}/${appConfig.RECEPTION_API_WAR_NAME}/api/v1/server`;
    }

    changeTransactionalOperation(status: TransactionalOperationEnum): Observable<any> {
        return this.http.put<JsonResponse>(`${this.baseUrl}/manageTransactionalOperation/${status}`, { headers: this.headers });
    }
}
