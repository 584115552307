<div id="carouselExampleIndicators" class="row carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner vh-100">
        <div class="carousel-item active vh-100" >
            <img src="../../../../assets/img/carrousel/uno.jpg" class="img-responsive w-100 h-100" alt="">
            <div class="carousel-caption pb-5 py-3 text-white">
                <h1 class="display-2 font-weight-bolder mb-0 px-5">Azurian Track</h1>
                <div  class="text-large font-weight-light px-5">Evita atrasos en el pago de facturas, disminuye el IVA no recuperado.</div>
            </div>
        </div>
        <div class="carousel-item vh-100" >
            <img src="../../../../assets/img/carrousel/dos.jpg" class="img-responsive w-100 h-100" alt="">
            <div class="carousel-caption pb-5 py-3 text-white">
                <h1 class="display-2 font-weight-bolder mb-0 px-5">Azurian LCE</h1>
                <div  class="text-large font-weight-light px-5">Da el siguiente paso en gestión tributaria electrónica.</div>
            </div>
        </div>
        <div class="carousel-item vh-100" >
            <img src="../../../../assets/img/carrousel/tres.jpg" class="img-responsive w-100 h-100" alt="">
            <div class="carousel-caption pb-5 py-3 text-white">
                <h1 class="display-2 font-weight-bolder mb-0 px-5">Azurian FDA</h1>
                <div class="text-large font-weight-light px-5 bajada">Logra que todo el ciclo documental sea digital, dando validez legal a tus documentos.</div>
            </div>
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>