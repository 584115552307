import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import esPE from '@angular/common/locales/es-PE';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { translateModuleConfig } from './translate/translateModuleConfig';

import { NgxPermissionsModule } from 'ngx-permissions';

import { NgEventBus } from 'ng-event-bus';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { ResumenClienteModule } from './resumen-cliente/resumen-cliente.module';
registerLocaleData(esPE);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserModule,
    
    BrowserAnimationsModule,
    RouterModule,
    TranslateModule.forRoot(translateModuleConfig),

    AppRoutingModule,
    LayoutModule,
    MatSnackBarModule,
    SharedModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    ResumenClienteModule
    ],
  providers: [
    NgEventBus,
    {
      provide: LOCALE_ID,
      useValue: 'es-PE'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: NZ_I18N, useValue: es_ES }, { provide: NZ_ICONS, useValue: icons }
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
