import { Component, OnInit } from '@angular/core';
import { NgEventBus } from 'ng-event-bus';

@Component({
  selector: 'azd-loader',
  templateUrl: './azd-loader.component.html',
  styleUrls: ['./azd-loader.component.scss']
})
export class AzdLoaderComponent implements OnInit {
  componentActive = true;
  loadingText = 'generic_components.loading';
  loading: boolean = false;
  type: string = "login";
  loadingPoint: number = 0;
  setIntervalLoading: any;

  constructor(
    private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
    this.eventBus.on("loading:layout").subscribe((trae: any) => {
      this.initLoading("layout", trae._data.loading);
    });

    this.eventBus.on("loading:login").subscribe((trae: any) => {
      this.initLoading("login", trae._data.loading);
    });
  }

  initLoading(type: "login" | "layout", loading: boolean) {
    this.type = type;
    this.loading = loading;
      
    if (this.loading) {
      this.setLoadingPoint();
    } else {
      setTimeout(() => {
        this.closeLoading();
      }, 2000);
    }
  }

  setLoadingPoint() {
    let i = 0;
    this.setIntervalLoading = setInterval(() => {
      i++;
      if (i === 1) {
        this.loadingPoint = 1;
      }

      if (i === 2) {
        this.loadingPoint = 2;
      }

      if (i === 3) {
        this.loadingPoint = 3;
      }

      if (i === 4) {
        this.loadingPoint = 0;
        i = 0;
      }
    }, 800);
  }

  closeLoading() {
    clearInterval(this.setIntervalLoading);
  }
}
