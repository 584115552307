import { IAppConfig } from './app-config.interface';
import { defaultAppConfig } from './default-app-config';

const isNil = (val: any) => val === null || val === undefined;

class MissingAppConfigProperty extends Error {
  constructor(propertyName: string) {
    const message = `The property '${propertyName}' is missing`;

    super(message);

    this.name = 'MissingAppConfigProperty';
  }
}

export const appConfig: IAppConfig = { ...defaultAppConfig };

export function loadAppConfig({
  ADDRESSEE_CONFIG_API_URL,
  AMBIENTE,
  APP_URL,
  APP_VERSION,
  AUDIT_API_URL,
  AUTH_API_URL,
  AZURE_LOGIN,
  BLACK_LIST_API_URL,
  COMPANY_API_URL,
  DIGITAL_CERTIFICATION_API_URL,
  DTE_CEN,
  DTE_XML_API_URL,
  HES,
  LAYOUT_API_URL,
  MASSIVE_DOWNLOAD_API_URL,
  NOTIFICATIONS_API_URL,
  ORDER_VALIDATION_API_URL,
  PASSWORD_POLICIES_API_URL,
  PORTAL,
  PURCHASE_ORDERS,
  RECEPCION_API_URL,
  RECEPTION_ADMINISTRATION_WAR_NAME,
  RECEPTION_API_WAR_NAME,
  REFRESH_TOKEN_MINUTES,
  ROLES_API_URL,
  SCORING_API_URL,
  SYSTEM_ACCOUNTS_API_URL,
  SYSTEM_PROPERTIES_API_URL,
  TOKEN_WAR_NAME,
  USER_API_URL,
  VALIDATION_RULES_API_URL,
  WHITE_LIST_API_URL
}: IAppConfig): void {
  if (isNil(ADDRESSEE_CONFIG_API_URL)) {
    throw new MissingAppConfigProperty('ADDRESSEE_CONFIG_API_URL');
  } else {
    appConfig.ADDRESSEE_CONFIG_API_URL = ADDRESSEE_CONFIG_API_URL;
  }

  if (isNil(AMBIENTE)) {
    throw new MissingAppConfigProperty('AMBIENTE');
  } else {
    appConfig.AMBIENTE = AMBIENTE;
  }

  if (isNil(APP_URL)) {
    throw new MissingAppConfigProperty('APP_URL');
  } else {
    appConfig.APP_URL = APP_URL;
  }

  if (isNil(APP_VERSION)) {
    throw new MissingAppConfigProperty('APP_VERSION');
  } else {
    appConfig.APP_VERSION = APP_VERSION;
  }

  if (isNil(AUDIT_API_URL)) {
    throw new MissingAppConfigProperty('AUDIT_API_URL');
  } else {
    appConfig.AUDIT_API_URL = AUDIT_API_URL;
  }

  if (isNil(AUTH_API_URL)) {
    throw new MissingAppConfigProperty('AUTH_API_URL');
  } else {
    appConfig.AUTH_API_URL = AUTH_API_URL;
  }

  if (isNil(AZURE_LOGIN)) {
    throw new MissingAppConfigProperty('AZURE_LOGIN');
  } else {
    appConfig.AZURE_LOGIN = AZURE_LOGIN;
  }

  if (isNil(BLACK_LIST_API_URL)) {
    throw new MissingAppConfigProperty('BLACK_LIST_API_URL');
  } else {
    appConfig.BLACK_LIST_API_URL = BLACK_LIST_API_URL;
  }

  if (isNil(COMPANY_API_URL)) {
    throw new MissingAppConfigProperty('COMPANY_API_URL');
  } else {
    appConfig.COMPANY_API_URL = COMPANY_API_URL;
  }

  if (isNil(DIGITAL_CERTIFICATION_API_URL)) {
    throw new MissingAppConfigProperty('DIGITAL_CERTIFICATION_API_URL');
  } else {
    appConfig.DIGITAL_CERTIFICATION_API_URL = DIGITAL_CERTIFICATION_API_URL;
  }

  if (isNil(DTE_CEN)) {
    throw new MissingAppConfigProperty('DTE_CEN');
  } else {
    appConfig.DTE_CEN = DTE_CEN;
  }

  if (isNil(DTE_XML_API_URL)) {
    throw new MissingAppConfigProperty('DTE_XML_API_URL');
  } else {
    appConfig.DTE_XML_API_URL = DTE_XML_API_URL;
  }

  if (isNil(HES)) {
    throw new MissingAppConfigProperty('HES');
  } else {
    appConfig.HES = HES;
  }

  if (isNil(LAYOUT_API_URL)) {
    throw new MissingAppConfigProperty('LAYOUT_API_URL');
  } else {
    appConfig.LAYOUT_API_URL = LAYOUT_API_URL;
  }

  if (isNil(MASSIVE_DOWNLOAD_API_URL)) {
    throw new MissingAppConfigProperty('MASSIVE_DOWNLOAD_API_URL');
  } else {
    appConfig.MASSIVE_DOWNLOAD_API_URL = MASSIVE_DOWNLOAD_API_URL;
  }

  if (isNil(NOTIFICATIONS_API_URL)) {
    throw new MissingAppConfigProperty('NOTIFICATIONS_API_URL');
  } else {
    appConfig.NOTIFICATIONS_API_URL = NOTIFICATIONS_API_URL;
  }

  if (isNil(ORDER_VALIDATION_API_URL)) {
    throw new MissingAppConfigProperty('ORDER_VALIDATION_API_URL');
  } else {
    appConfig.ORDER_VALIDATION_API_URL = ORDER_VALIDATION_API_URL;
  }

  if (isNil(PASSWORD_POLICIES_API_URL)) {
    throw new MissingAppConfigProperty('PASSWORD_POLICIES_API_URL');
  } else {
    appConfig.PASSWORD_POLICIES_API_URL = PASSWORD_POLICIES_API_URL;
  }

  if (isNil(PORTAL)) {
    throw new MissingAppConfigProperty('PORTAL');
  } else {
    appConfig.PORTAL = PORTAL;
  }

  if (isNil(PURCHASE_ORDERS)) {
    throw new MissingAppConfigProperty('PURCHASE_ORDERS');
  } else {
    appConfig.PURCHASE_ORDERS = PURCHASE_ORDERS;
  }

  if (isNil(RECEPCION_API_URL)) {
    throw new MissingAppConfigProperty('RECEPCION_API_URL');
  } else {
    appConfig.RECEPCION_API_URL = RECEPCION_API_URL;
  }

  if (isNil(RECEPTION_ADMINISTRATION_WAR_NAME)) {
    throw new MissingAppConfigProperty('RECEPTION_ADMINISTRATION_WAR_NAME');
  } else {
    appConfig.RECEPTION_ADMINISTRATION_WAR_NAME = RECEPTION_ADMINISTRATION_WAR_NAME;
  }

  if (isNil(RECEPTION_API_WAR_NAME)) {
    throw new MissingAppConfigProperty('RECEPTION_API_WAR_NAME');
  } else {
    appConfig.RECEPTION_API_WAR_NAME = RECEPTION_API_WAR_NAME;
  }

  if (isNil(REFRESH_TOKEN_MINUTES)) {
    throw new MissingAppConfigProperty('REFRESH_TOKEN_MINUTES');
  } else {
    appConfig.REFRESH_TOKEN_MINUTES = REFRESH_TOKEN_MINUTES;
  }

  if (isNil(ROLES_API_URL)) {
    throw new MissingAppConfigProperty('ROLES_API_URL');
  } else {
    appConfig.ROLES_API_URL = ROLES_API_URL;
  }

  if (isNil(SCORING_API_URL)) {
    throw new MissingAppConfigProperty('SCORING_API_URL');
  } else {
    appConfig.SCORING_API_URL = SCORING_API_URL;
  }

  if (isNil(SYSTEM_ACCOUNTS_API_URL)) {
    throw new MissingAppConfigProperty('SYSTEM_ACCOUNTS_API_URL');
  } else {
    appConfig.SYSTEM_ACCOUNTS_API_URL = SYSTEM_ACCOUNTS_API_URL;
  }

  if (isNil(SYSTEM_PROPERTIES_API_URL)) {
    throw new MissingAppConfigProperty('SYSTEM_PROPERTIES_API_URL');
  } else {
    appConfig.SYSTEM_PROPERTIES_API_URL = SYSTEM_PROPERTIES_API_URL;
  }

  if (isNil(TOKEN_WAR_NAME)) {
    throw new MissingAppConfigProperty('TOKEN_WAR_NAME');
  } else {
    appConfig.TOKEN_WAR_NAME = TOKEN_WAR_NAME;
  }

  if (isNil(USER_API_URL)) {
    throw new MissingAppConfigProperty('USER_API_URL');
  } else {
    appConfig.USER_API_URL = USER_API_URL;
  }

  if (isNil(VALIDATION_RULES_API_URL)) {
    throw new MissingAppConfigProperty('VALIDATION_RULES_API_URL');
  } else {
    appConfig.VALIDATION_RULES_API_URL = VALIDATION_RULES_API_URL;
  }

  if (isNil(WHITE_LIST_API_URL)) {
    throw new MissingAppConfigProperty('WHITE_LIST_API_URL');
  } else {
    appConfig.WHITE_LIST_API_URL = WHITE_LIST_API_URL;
  }
}
