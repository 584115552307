import { Component, OnInit } from '@angular/core';
import { appConfig } from 'src/app-config/app-config';
import { AuthService } from './auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title: string = 'app-component';
  isLogin: boolean = false;
  token: any;
  username: any;

  allTooltip: any;

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.token = localStorage.getItem(appConfig.PORTAL + "_access_token");
    this.username = localStorage.getItem(appConfig.PORTAL + "_username");
    this.isLogin = JSON.parse(localStorage.getItem(appConfig.PORTAL + "_is_logged_in") ?? "false");

    if (this.isLogin) {
      if (this.token != "" && this.username != "") {
        this.authService.refreshToken(this.token, this.username).subscribe((res) => {
          console.log(`Route Memory -> ${this.authService._getRouterMemory}`);
          this.router.navigateByUrl(this.authService._getRouterMemory === null ? '/home' : this.authService._getRouterMemory)
        }, (err: any) => {
          console.log(err);
          console.log("--- NO REFRESH TOKEN ---");
          this.authService.logout();
        });
      }
    }
  }
}
