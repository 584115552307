import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ResumenClienteComponent } from './resumen-cliente.component';
import { CardModule } from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
  declarations: [ResumenClienteComponent],
  imports: [
    CommonModule,
    TableModule,
    CardModule,
    TabViewModule
  ]
})
export class ResumenClienteModule { }
