<ng-select
  class="ng-select-primary"
  [items]="items"
  [multiple]="true"
  [closeOnSelect]="false"
  [readonly]="disabled"
  [(ngModel)]="value"
  (blur)="onBlur()"
>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />&nbsp;&nbsp;{{ item.label }}
  </ng-template>
</ng-select>
