import { BsDatepickerConfig, BsDatepickerViewMode, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

const noop = () => {};

export const DATEPICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AzDatepickerComponent),
  multi: true,
};

@Component({
  selector: 'az-datepicker',
  templateUrl: './az-datepicker.component.html',
  styleUrls: ['./az-datepicker.component.scss'],
  providers: [DATEPICKER_CONTROL_VALUE_ACCESSOR],
})
export class AzDatepickerComponent implements ControlValueAccessor, OnInit {
  private onChangeCallback: (_: string | Date | null) => void = noop;
  private onTouchedCallback: () => void = noop;
  
  @Input() public innerValue: string | Date | null = null;
  @Input() public min: Date = new Date(moment().add(-10, "years").format("YYYY-MM-DD"));
  @Input() public max: Date = new Date(moment().format("YYYY-MM-DD"));
  @Input() public disabled: boolean = false;
  @Input() public required: boolean = false;
  @Input() public format: string = 'YYYY-MM-DD';
  @Input() public showWeekNumbers: boolean = false;
  @Input() public type: BsDatepickerViewMode | null = null;
  @Output() public change: EventEmitter<string | Date | null> = new EventEmitter<string | Date | null>();

  config!: Partial<BsDatepickerConfig>;

  constructor(traductionCalendar: BsLocaleService) {
    traductionCalendar.use('es');
  }

  ngOnInit(): void {
    this.config = { dateInputFormat: this.format, showWeekNumbers: this.showWeekNumbers, isAnimated: true };
    if (this.type != null) {
      this.config.dateInputFormat = this.type === 'year' ? 'YYYY' : this.type === 'month' ? 'MMMM' : this.type === 'day' ? 'DD' : 'YYYY-MM-DD';
      this.config.minMode = this.type;

      if (this.type === 'month') {
        this.config.containerClass = 'hidden-head';
      }
    }
  }

  @Input() public set now(isNow: boolean) {
    this.value = isNow ? new Date() : null;
  }

  public get value(): string | Date | null {
    return this.innerValue;
  }

  public set value(v: string | Date | null) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.change.emit(v);
    }
  }

  public onBlur() {
    this.onTouchedCallback();
  }

  public registerOnChange(fn: (value: string | Date | null) => any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: string | Date | null): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }
}