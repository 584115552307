import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  isLogin: any;
  token: string | null = "";
  username: string | null = "";

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}
  
  componentActive: boolean = true;
  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkApiAuthentication().pipe(
      map(storeOrApiAuth => {
        if (!storeOrApiAuth) {
          this.authService.authSuccessUrl = state.url;
          this.router.navigate(["/login"]);
          return false;
        }

        this.restartLoginTimeout();

        return true;
      })
    );
  }

  checkStoreAuthentication() {
    return this.authService.authenticated ?? false;
  }

  checkApiAuthentication() {
    return of(this.authService.authenticated);
  }

  restartLoginTimeout() {
    this.authService.setAuthFromStorage();
  }
}
