<style>
    @import url('https://fonts.googleapis.com/css2?family=Anek+Latin&family=Belanosima&family=Josefin+Sans:wght@500&family=Oswald:wght@500&display=swap');
</style>

<div class="cabecera">
    <h2 style="text-align: center; font-family: 'Belanosima';">Resumen del cliente</h2>
</div>
<div class="contenedorPrincipal">
    <div class="titulos">
        <div class="datosClienteCabecera">
            <div class="tituloDatosCliente">
                <span>Datos de cliente</span>
            </div>
            <span style="text-align: left;"><span style="font-weight: bold;">Nombre: </span>{{nombreCliente}}</span>
            <span style="text-align: left;"><span style="font-weight: bold;">Correo: </span>{{correoCliente}}</span>
            <span style="text-align: left;"><span style="font-weight: bold;">Teléfono: </span>{{numTelfCliente}}</span>
        </div>
        <div class="divSubtituloPrimero">
            <span class="subTitulo subTituloPrimero">Capital adeudado</span>
            <span class="valorSubtitulo">{{capitalAdeudadoCliente | number: "1.0-2"}}$</span>
        </div>
        <div class="divSubtituloMedio">
            <span class="subTitulo subTituloMedio">Interés total acumulado</span>
            <span class="valorSubtitulo">{{interesesAcumuladosCliente | number: "1.0-2"}}$</span>
        </div>
        <div class="divSubtituloUltimo">
            <span class="subTitulo subTituloUltimo">Interés total</span>
            <span class="valorSubtitulo">{{interesTotalCliente | number: "1.0-2"}}$</span>
        </div>
    </div>
    <div class="cuerpo">
        <p-tabView (onChange)="cambioPestana($event)">
            <!-- <div class="cabeceraCuerpo">
                <h4>Lista de créditos - <span style="font-weight: bold;">{{totalCreditosVigentes}}</span></h4>
            </div> -->
            <p-tabPanel header="Vigentes">
                <div class="contenedorTarjetas">
                    <div class="tarjetaCredito" *ngFor="let credito of creditosVigentes">
                        <p-card>
                            <div class="cabeceraTarjeta">
                                <div class="saldoInsolutoDiv">
                                    <span style="font-family: 'Belanosima';">Monto otorgado</span>
                                    <span style="margin-bottom: 1%;">{{credito.monto_credito | number: "1.0-2"}} $</span>
                                </div>
                                <div class="saldoActualDiv">
                                    <span style="font-family: 'Belanosima';">Saldo vigente</span>
                                    <span style="margin-bottom: 1%;">{{credito.montoCreditoPendiente | number: "1.0-2"}} $</span>
                                </div>
                            </div>
                            <div class="cuerpoTarjeta">
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Intereses generados</span>
                                    <span>{{credito.interesesDesdeUltimoPago | number: "1.0-2"}}$</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Interés adeudado</span>
                                    <span>{{credito.interesAcumulados | number: "1.0-2"}}$</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Tipo</span>
                                    <span>{{credito.tipo_credito}}</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Tasa de interés</span>
                                    <span>{{credito.tasa_interes}}%</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Fecha otorgamiento</span>
                                    <span>{{credito.fecha_credito}}</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Moneda</span>
                                    <span>Dólar ($)</span>
                                </div>
                            </div>
                            <div class="pieTarjeta" style="padding-bottom: 4%;">
                                <div class="estadoCreditoDiv">
                                    <span class="creditoVigenteEstilo">{{credito.estado_credito}}</span>
                                </div>
                            </div>
                        </p-card>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Pagados">
                <div class="contenedorTarjetas">
                    <div class="tarjetaCredito" *ngFor="let credito of creditosPagados">
                        <p-card>
                            <div class="cabeceraTarjeta">
                                <div class="saldoInsolutoDiv">
                                    <span style="font-family: 'Belanosima';">Monto otorgado</span>
                                    <span style="margin-bottom: 1%;">{{credito.monto_credito | number: "1.0-2"}} $</span>
                                </div>
                                <div class="saldoActualDiv">
                                    <span style="font-family: 'Belanosima';">Saldo vigente</span>
                                    <span style="margin-bottom: 1%;">{{credito.montoCreditoPendiente | number: "1.0-2"}} $</span>
                                </div>
                            </div>
                            <div class="cuerpoTarjeta">
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Intereses generados</span>
                                    <span>{{credito.interesesDesdeUltimoPago | number: "1.0-2"}}$</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Interés adeudado</span>
                                    <span>{{credito.interesAcumulados | number: "1.0-2"}}$</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Tipo</span>
                                    <span>{{credito.tipo_credito}}</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Tasa de interés</span>
                                    <span>{{credito.tasa_interes}}%</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Fecha otorgamiento</span>
                                    <span>{{credito.fecha_credito}}</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Moneda</span>
                                    <span>Dólar ($)</span>
                                </div>
                            </div>
                            <div class="pieTarjeta" style="padding-bottom: 4%;">
                                <div class="estadoCreditoDiv">
                                    <span class="creditoPagadoEstilo"><!--{{credito.estado_credito}}-->Pagado</span>
                                </div>
                            </div>
                        </p-card>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Cancelados">
                <div class="contenedorTarjetas">
                    <div class="tarjetaCredito" *ngFor="let credito of creditosCancelados">
                        <p-card>
                            <div class="cabeceraTarjeta">
                                <div class="saldoInsolutoDiv">
                                    <span style="font-family: 'Belanosima';">Monto otorgado</span>
                                    <span style="margin-bottom: 1%;">{{credito.monto_credito | number: "1.0-2"}} $</span>
                                </div>
                                <div class="saldoActualDiv">
                                    <span style="font-family: 'Belanosima';">Saldo vigente</span>
                                    <span style="margin-bottom: 1%;">{{credito.montoCreditoPendiente | number: "1.0-2"}} $</span>
                                </div>
                            </div>
                            <div class="cuerpoTarjeta">
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Intereses generados</span>
                                    <span>{{credito.interesesDesdeUltimoPago | number: "1.0-2"}}$</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Interés adeudado</span>
                                    <span>{{credito.interesAcumulados | number: "1.0-2"}}$</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Tipo</span>
                                    <span>{{credito.tipo_credito}}</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Tasa de interés</span>
                                    <span>{{credito.tasa_interes}}%</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Fecha otorgamiento</span>
                                    <span>{{credito.fecha_credito}}</span>
                                </div>
                                <div>
                                    <span class="subTextoCuerpoTarjeta">Moneda</span>
                                    <span>Dólar ($)</span>
                                </div>
                            </div>
                            <div class="pieTarjeta" style="padding-bottom: 4%;">
                                <div class="estadoCreditoDiv">
                                    <span class="creditoPagadoEstilo">{{credito.estado_credito}}</span>
                                </div>
                            </div>
                        </p-card>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel [disabled]="true" header="Créditos - {{numCreditosPestana}}"></p-tabPanel>
        </p-tabView>
    </div>
</div>
<!-- <p-table [value]="datosCreditos">
    <ng-template pTemplate="header">
        <tr>
            <th>Monto capital</th>
            <th>Monto actual</th>
            <th>Tasa de interés</th>
            <th>Interés adeudado</th>
            <th>Interés desde último pago</th>
            <th>Pagos realizados</th>
            <th>Monto de pagos</th>
            <th>Tipo de crédito</th>
            <th>Fecha de crédito</th>
            <th>Estado</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-credito>
        <tr>
            <td>{{credito.monto_credito}}</td>
            <td>{{credito.montoCreditoPendiente}}</td>
            <td>{{credito.tasa_interes}}</td>
            <td>{{credito.interesAcumulados}}</td>
            <td>{{credito.interesesDesdeUltimoPago}}</td>
            <td>{{credito.resumenDatosPagosCreditos.cantidadPagosCredito}}</td>
            <td>{{credito.resumenDatosPagosCreditos.sumatoriaPagosCredito}}</td>
            <td>{{credito.tipo_credito}}</td>
            <td>{{credito.fecha_credito}}</td>
            <td>{{credito.estado_credito}}</td>
        </tr>
    </ng-template>
</p-table> -->