import { DragulaModule } from 'ng2-dragula';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { AzDatepickerComponent } from './containers/az-datepicker/az-datepicker.component';
import { AzDragDropComponent } from './containers/az-drag-drop/az-drag-drop.component';
import { AzMultiCheckboxComponent } from './containers/az-multi-checkbox/az-multi-checkbox.component';

@NgModule({
  declarations: [
    // Components
    AzDatepickerComponent,
    AzDragDropComponent,
    AzMultiCheckboxComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    // Third Party Modules
    BsDatepickerModule,
    DragulaModule,
    TranslateModule,
    NgSelectModule,
  ],
  exports: [
    // Components
    AzDatepickerComponent,
    AzDragDropComponent,
    AzMultiCheckboxComponent,
  ],
})
export class AzInputControlModule {}
