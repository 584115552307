import { DragulaModule } from 'ng2-dragula';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AzdLoaderComponent } from './components/azd-loader/azd-loader.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { NavComponent } from './components/nav/nav.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PassMatchDirective } from './directive/pass-match.directive';
import { TranslateValidatorMessageLoader } from './services/translate-validator-message-loader';
import { ValidatorMessageLoader } from './validation-message/services/validator-message-loader';
import { ValidationMessageModule } from './validation-message/validation-message.module';

import { AzInputControlModule } from './az-input-control';
import { AzTableModule } from './az-table';
import { AzNotRegisterComponent } from './components/az-not-register/az-not-register.component';
import { AzLoadingBaseComponent } from './components/az-loading-base/az-loading-base.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AzSelectFilterModule } from './components/az-select-filter/az-select-filter.module';
import { RucDirective } from './directive/ruc.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

defineLocale('es', esLocale);

export function createValidationLoader(translate: TranslateService) {
  return new TranslateValidatorMessageLoader(translate);
}

@NgModule({
  declarations: [
    CarouselComponent,
    LanguageSelectorComponent,
    NavComponent,
    SidebarComponent,
    AzdLoaderComponent,
    PassMatchDirective,
    AzNotRegisterComponent,
    AzLoadingBaseComponent,
    RucDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragulaModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgSelectModule,
    NgxPaginationModule,
    TranslateModule,
    ValidationMessageModule.forRoot({
      loader: {
        provide: ValidatorMessageLoader,
        useFactory: createValidationLoader,
        deps: [TranslateService],
      },
    }),
    AzTableModule.forRoot({
      sortable: true,
    }),
    AzInputControlModule,
    NgxChartsModule,
    MatDialogModule,
    AzSelectFilterModule,
    MatSlideToggleModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    DragulaModule,
    NgxMaskModule,
    NgSelectModule,
    TranslateModule,
    ValidationMessageModule,
    AzTableModule,
    AzInputControlModule,
    CarouselComponent,
    LanguageSelectorComponent,
    NavComponent,
    AzdLoaderComponent,
    SidebarComponent,
    PassMatchDirective,
    AzNotRegisterComponent,
    AzLoadingBaseComponent,
    NgxChartsModule,
    MatDialogModule,
    AzSelectFilterModule,
    RucDirective,
    MatSlideToggleModule
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    var providers: Provider[] = [];
    if (NgxMaskModule.forRoot().providers) {
      providers.push(...(NgxMaskModule.forRoot().providers as Provider[]));
    }
    return {
      ngModule: SharedModule,
      providers: [providers],
    };
  }
}
