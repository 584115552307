import { DragulaService } from 'ng2-dragula';

import { Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DragDropGroup } from '../../models/drag-drop-group';

const noop = () => {};

export const DRAG_DROP_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AzDragDropComponent),
  multi: true,
};

@Component({
  selector: 'az-drag-drop',
  templateUrl: './az-drag-drop.component.html',
  styleUrls: ['./az-drag-drop.component.scss'],
  providers: [DRAG_DROP_CONTROL_VALUE_ACCESSOR],
})
export class AzDragDropComponent implements ControlValueAccessor {
  // #region Properties

  private innerValue: any = '';
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;

  @Input() public groups: Array<DragDropGroup> = [];
  @Input() itemOutlet!: TemplateRef<any>;
  @Output() public change: EventEmitter<Array<DragDropGroup> | null> = new EventEmitter<Array<DragDropGroup> | null>();

  // #endregion Properties

  // #region Constructors

  constructor(private dragulaService: DragulaService) {
    this.dragulaService.createGroup('COLUMNS', {
      direction: 'horizontal',
      moves: (el, source, handle) => handle?.className === 'group-handle',
    });
  }

  // #endregion Constructors

  // #region Public Accessors

  public get value(): any {
    return this.innerValue;
  }

  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // #endregion Public Accessors

  // #region Public Methods

  public onBlur() {
    this.onTouchedCallback();
  }

  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // #endregion Public Methods
}
