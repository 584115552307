<div class="wrapper-sidebar">
	<div class="left">
		<span (click)="toggleNavigation()" class="icon-burger fi fi-br-menu-burger"></span>
	</div>
	<div class="right">
		<div class="dropdown width-180">
			<button type="button" class="btn btn-light dropdown-toggle button-user" data-toggle="dropdown">
				<ng-container *ngIf="user != null">
					{{ user }}
				</ng-container>
				
				<span class="ion ion-md-person" style="margin-left: 0.25rem"></span>
			</button>
			<div class="dropdown-menu" style="left: -1rem">
				<div class="dropdown-item no-active">
					<app-language-selector></app-language-selector>
				</div>
				<a class="dropdown-item" routerLink="/profile/cambiar-clave"><span class="ion ion-md-key"></span>
					{{ BASE_TRANSLATE_SHARED + '.options_text.op_change_pass' | translate }}</a>
				<a class="dropdown-item cursor-pointer" (click)="logout()"><span
						class="ion ion-md-log-out color-red"></span> {{ BASE_TRANSLATE_SHARED + '.options_text.op_logout' | translate }}</a>
			</div>
		</div>
	</div>
</div>