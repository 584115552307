<ng-template #pageBlockTemplate>
  <div class="block-ui-template">
    <div class="sk-folding-cube sk-primary">
      <div class="sk-cube1 sk-cube"></div>
      <div class="sk-cube2 sk-cube"></div>
      <div class="sk-cube4 sk-cube"></div>
      <div class="sk-cube3 sk-cube"></div>
    </div>
    <h5 class="text-center mb-0" style="color: white"></h5>
  </div>
</ng-template>
 
<router-outlet></router-outlet>

<azd-loader></azd-loader>