import { Directive, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RucHelper } from '../utilities/ruc-helper';

@Directive({
  selector: '[validRuc]'
})
export class RucDirective {
  constructor(@Self() private ngControl: NgControl) {}

  @HostListener('keydown') onKeyDown() {
    this.formatControl();
  }

  @HostListener('keyup') onKeyUp() {
    this.formatControl();
  }

  formatControl() {
    let _control: any = this.ngControl.control;
    _control.setValue(
      RucHelper.format(this.ngControl.value, true)
    );
  }
}
