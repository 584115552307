<div class="az-datepicker">
  <input
    #dp="bsDatepicker"
    bsDatepicker
    [bsConfig]="config"
    [(ngModel)]="value"
    type="text"
    class="inputDatePicker"
    (blur)="onBlur()"
    [minDate]="min"
    [maxDate]="max"
    [disabled]="disabled"
    [required]="required"
    readonly
  />
  <span *ngIf="!disabled" (click)="dp.toggle()" class="az-datepicker__icon fa fa-calendar-alt"></span>
  <span *ngIf="disabled" class="az-datepicker__icon fa fa-calendar-alt disabled"></span>
</div>
