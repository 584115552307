<div class="boxWrapperSelect" [ngClass]="disabled === true ? 'select-filter-disabled' : ''" (click)="disabled === false ? openShow($event) : ''">
  <p class="boxWrapperSelect__selection" [ngClass]="type === 'selectionMultiple' ? 'checkboxItem' : ''" [innerHTML]="placeholder | safeHtmlSelectFilter"></p>

  <div az-select-filter class="boxWrapperSelect__boxSelect">
    <div az-select-filter class="boxSelect__content activeScroollerAuto">
      <p az-select-filter class="boxSelect__item formFilter borderBottom noShowInput" style="height: 0;">
        <input
          type="text"
          az-select-filter
          placeholder="Buscar"
          autocomplete="off"
          autocorrect="off"
          [disabled]="data.length ? false : true"
          (keyup)="searchFilter($event)"
          (keydown)="searchFilter($event)"
          autocapitalize="none"
          class="input-search-filter"
        />
      </p>

      <p
        *ngFor="let d of data; let i = index"
        az-select-filter
        [attr.type-select-filter]="type"
        datafilter="activo"
        class="boxSelect__item borderBottom"
        [ngClass]="type === 'selectionMultiple' ? 'checkboxItem' : d.img != undefined ? 'imgItem' : ''" 
        (click)="selected($event)"
        [attr.value-select-filter]="d.id"
        [attr.selected]="d.selected"
      >
        <span az-select-filter *ngIf="type === 'selectionMultiple'" class="boxSelect__item--checked" [ngClass]="d.selected ? 'checked' : ''"><span class="checked__circle"></span></span>
        <img  az-select-filter *ngIf="d.img != undefined" onerror="this.src = 'assets/img/flag-icons/Unknown.png'" [src]="d.img" [alt]="d.text">
        <span az-select-filter class="text">{{ d.text }}</span>
      </p>

      <p *ngIf="data.length" az-select-filter class="boxSelect__item no-result no-display">no hay resultado</p>
      <p *ngIf="!data.length" az-select-filter class="boxSelect__item no-result-sindata text-center">Sin datos</p>
    </div>
  </div>
</div>