export enum ActionTypePermission {

  CREATE_TRANSACTIONS = 'CREATE_TRANSACTIONS',
  READ_TRANSACTIONS = 'READ_TRANSACTIONS',
  
  VISUALIZAR_EMPRESAS = 'Visualizar empresas',
  CREAR_EMPRESA = 'Crear empresa',
  MODIFICAR_EMPRESA = 'Modificar empresa',
  CONFIGURAR_PROP_EMPRESA = 'Configurar propiedades empresa',
  CONFIGURAR_ALERTAS_EMPRESA = 'Configurar alertas empresa',

  VISUALIZAR_USUARIOS = 'Visualizar usuarios',
  CARGAR_USUARIO = 'Cargar usuario',
  CREAR_USUARIO = 'Crear usuario',
  MODIFICAR_USUARIO = 'Modificar usuario',
  VER_ASIGNACIONES_USUARIO = 'Ver asignaciones',
  DUPLICAR_USUARIO_OTRA_EMP = 'Duplicar usuario en otra empresa',
  REGENERAR_CONTRASENA_USUARIO = 'Regenerar contraseña',
  REACTIVAR_CONTRASENA_USUARIO = 'Reactivar contraseña',

  VISUALIZAR_PROP_SISTEMA = 'Visualizar propiedades sistema',
  GUARDAR_PROP_SISTEMA = 'Guardar propiedades sistema',

  VISUALIZAR_CUENTAS_SISTEMA = 'Visualizar cuentas sistema',
  CREAR_CUENTA_SISTEMA = 'Crear cuenta sistema',
  ELIMINAR_CUENTA_SISTEMA = 'Eliminar cuenta sistema',
  MODIFICAR_CUENTA_SISTEMA = 'Modificar cuenta sistema',
  PROBAR_CUENTA_SISTEMA = 'Probar cuenta sistema',

  VISUALIZAR_POLITICAS_CLAVE = 'Visualizar politicas clave',

  VISUALIZAR_AUDITORIA = 'Visualizar auditoria',

  VISUALIZAR_ALERTAS_NOTIFICACIONES = 'Visualizar alertas y notificaciones',
  CONFIGURAR_ALERTAS_NOTIFICACIONES = 'Configurar alertas y notificaciones',

  CONFIGURAR_NOTIFICACIONES_DE_RECHAZO = 'Notificación Rechazo',

  VISUALIZAR_ROLES = 'Visualizar roles',
  CREAR_ROL = 'Crear rol',
  MODIFICAR_ROL = 'Modificar rol',
  ELIMINAR_ROL = 'Eliminar Rol',
  ASIGNAR_PERMISO_ROL = 'Asignar permisos',

  VISUALIZAR_UNIDADES_ADMINISTRATIVAS = 'Visualizar unidades administrativas',
  CREAR_UNIDAD_ADMINISTRATIVA = 'Crear unidad administrativa',
  MODIFICAR_UNIDAD_ADMINISTRATIVA = 'Modificar unidad administrativa',
  ELIMINAR_UNIDAD_ADMINISTRATIVA = 'Eliminar unidad administrativa',

  ASIGNAR_SUB_UNIDAD_ADMINISTRATIVA = 'Asignar sub unidad administrativa',
  ASIGNAR_USUARIO_UNI_ADM = 'Asignar usuario unidad adm.',
  VISUALIZAR_SUB_UNIDAD_ADM = 'Visualizar sub unidades administrativas',
  CREAR_SUB_UNIDAD_ADM = 'Crear sub unidad administrativa',
  MODIFICAR_SUB_UNIDAD_ADM = 'Modificar sub unidad administrativa',
  ELIMINAR_SUB_UNIDAD_ADM = 'Eliminar sub unidad administrativa',
  ASIGNAR_USUARIO_SUB_UNI_ADM = 'Asignar usuario sub unidad adm.',

  VISUALIZAR_RUT_EXCLUIDOS = 'Visualizar RUT excluidos',
  CREAR_RUT_EXCLUIDO = 'Crear RUT excluido',
  MODIFICAR_RUT_EXCLUIDO = 'Modificar RUT excluido',
  ELIMINAR_RUT_EXCLUIDO = 'Eliminar RUT excluido',

  VISUALIZAR_CONTACTOS_PROVEEDOR = 'Visualizar contactos proveedor',
  MODIFICAR_CONTACTO_PROVEEDOR = 'Modificar contacto proveedor',
  CARGAR_CONTACTOS_PROVEEDOR = 'Cargar contactos proveedor',
  CREAR_CONTACTO_PROVEEDOR = 'Crear nuevo contacto proveedor',
  VISUALIZAR_CONTACTOS_DEFECTO = 'Visualizar contactos por defecto',
  MODIFICAR_CONTACTO_DEFECTO = 'Modificar contacto por defecto',
  ELIMINAR_CONTACTO_DEFECTO = 'Eliminar contacto por defecto',
  CREAR_CONTACTO_DEFECTO = 'Crear contacto por defecto',
  VISUALIZAR_ACTUALIZACIONES_HISTORICAS = 'Visualizar actualizaciones historicas',

  VISUALIZAR_DTE_RECEPCIONADOS = 'Visualizar DTE recepcionados',
  ASIGNAR_UNIDAD_ADMINISTRATIVA = 'Asignar unidad administrativa',
  ACCION_COMERCIAL_DTE_RECEPCIONADOS = 'Aceptar o Reclamar DTE recepcionados',

  VISUALIZAR_SCORING = 'Visualizar Scoring',

  VISUALIZAR_REGLAS_VALIDACION = 'Visualizar Reglas de validacion',
  ACCIONES_REGLAS_VALIDACION = 'Acciones Reglas de validacion',

  VISUALIZAR_DESCARGA_MASIVA = 'Visualizar Descarga masiva',

  VISUALIZAR_BUSCADOR_DTE_CEN = 'Visualizar Buscador Cen',
  VISUALIZAR_GENERADOR_CSV_CEN = 'Visualizar Generador CSV CEN',

  VISUALIZAR_BUSCADOR_OC = 'Visualizar Buscador Ordenes de Compra',

  VISUALIZAR_CERTIFICADO_DIGITAL = 'Visualizar Certificado Digital',

  VISUALIZAR_MODULO_HES = 'Visualizar Modulo HES',
  CARGA_MASIVA_HES = 'Cargar Masivamente HES',

  CONFIGURACION_SFTP = 'Configurar Sftp'
}
