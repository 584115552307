import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import * as _ from 'lodash';

import { appConfig } from 'src/app-config/app-config';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private readonly baseServiceUrl = appConfig.RECEPCION_API_URL + '/' + appConfig.RECEPTION_API_WAR_NAME;

  private userProfileUrl = `${this.baseServiceUrl}/api/v1/server/getUserProfile`;

  private _username: string = "";
  get userName(): string {
    return this._username;
  }
  set userName(value: string) {
    this._username = value;
  }

  constructor(private http: HttpClient) { }

  getUserProfile(): Observable<any> {
    let data = {
      "code": 0,
      "message": "OK",
      "response": {
          "profile": {
              "username": "as",
              "companyId": 1,
              "createdOn": "2023-02-28T09:37:42.661Z",
              "emailAddress": "rubilla@azurian.com",
              "fullName": "AS",
              "holdingId": 1,
              "nationalId": "11111112",
              "nationalIdCheckChar": "1",
              "status": 1,
              "systemAdministrator": true,
              "type": 2,
              "roles": [
                  {
                      "id": 1,
                      "createdOn": "2023-03-13T19:30:12.487Z",
                      "description": "Usuarios transaccionales",
                      "name": "COMPANY USER TRANSACTIONAL",
                      "status": "ACTIVE",
                      "type": "COMPANY",
                      "permissions": [
                          "READ_TRANSACTIONS",
                          "CREATE_TRANSACTIONS"
                      ]
                  }
              ],
              "permissions": [
                  "READ_TRANSACTIONS",
                  "CREATE_TRANSACTIONS"
              ]
          },
          "transactionalOperation": "ENABLED"
      }
  };
  return of(data);
    // return this.http.get<any>(this.userProfileUrl);
  }
}
