import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';

import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-layout-blank',
  templateUrl: './layout-blank.component.html',
  styleUrls: ['./layout-blank.component.scss']
})
export class LayoutBlankComponent implements OnInit {
  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (typeof this.authService.authenticated === 'boolean' && this.authService.authenticated === true) {
      this.router.navigateByUrl('/home');
    } else {
      this.layoutService.showNavigation$ = of(true);
      this.authService.logout();
    }
  }
}